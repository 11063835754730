import {Button, Space, DatePicker, Popover} from "antd";
import React from "react";
import {EditOutlined} from "@ant-design/icons";

const {RangePicker} = DatePicker

export const DateRangePickerPopover: React.FC<{onOk: (startDate: string | null, endDate: string | null) => void}>
    = (props) => {
    const [visible, setVisible] = React.useState(false)

    let startDate: string | null = null;
    let endDate: string | null = null;

    const handleDateChange = (date: any, dateString: [string, string]) => {
        if (date !== null) {
            startDate = dateString[0]
            endDate = dateString[1]
        }
    }

    return (
        <Popover
            open={visible}
            title={"修改日期"}
            content={
                <Space direction={"vertical"}>
                    <RangePicker
                        // disabledDate={(current) => current && current < moment().endOf('day')}
                        onChange={handleDateChange} />

                    <Space>
                        <Button onClick={() => setVisible(false)}>
                            取消
                        </Button>
                        <Button type={"primary"}
                                onClick={ () => {
                                    setVisible(false)
                                    props.onOk(startDate, endDate)
                                }
                                }
                        >
                            确认
                        </Button>
                    </Space>

                </Space>
            }
            trigger={"click"}
        >
            <EditOutlined
                onClick={() => setVisible(true)}
                style={{color:'#5CA8F8'}}
            />
        </Popover>
    )
}