import {Button, Empty, message, Table, Modal, Typography} from "antd";

import React, {useCallback, useState} from "react";
import {useEffect} from "react";
import {launchApplyColumn} from "./launchApplyColumn";
import {useMyOutletContext} from "../../../pages/basePage";
import {handleWebsiteRequest, handleWebsiteResponse} from "../../../utils/tools";
import {deleteRecommendApplyApi, getRecommendApplyApi} from "../../../utils/api";
import {RecommendApplyListItem} from "../../../model/recommendApply";

const {Paragraph, Text} = Typography;

interface ApplyTableProps {
    forceRefresh: boolean
    onAdd: () => void
}

export const ApplyTable: React.FC<ApplyTableProps> = (props) => {
    const {userStatue: [userStatue,]} = useMyOutletContext()

    const [applyList, setApplyList] = useState<RecommendApplyListItem[]>([])

    const refreshApplyList = useCallback(async () => {
        if (userStatue.isLogin) {
            let res = await handleWebsiteRequest(getRecommendApplyApi, {})

            await handleWebsiteResponse(res, (response) => {
                setApplyList(response.Applies)
            }, (_) => {
                message.warning("参数错误")
            }, (_) => {
                message.warning("服务器异常")
            })
        } else {
            setApplyList([])
        }
    }, [userStatue.isLogin])

    useEffect(() => {
        refreshApplyList()
    }, [props.forceRefresh, userStatue.isLogin])

    const showAppliesInfo = (comments: string | null, state: number) => {
        if (state === -1) {
            return Modal.info({
                title: "审核中",
                content:
                    <Typography>
                        <Paragraph>
                            您的申请正在等待被审核
                        </Paragraph>
                        <Paragraph>
                            我们将会从<Text strong>公告内容、活动形式、申请时间、近期申请次数等</Text>多方面综合评判
                        </Paragraph>
                        <Paragraph>
                            <Text strong>这一流程通常不会超过三天</Text> ，请耐心等待。如长时间未审核，请联系管理员进行咨询
                        </Paragraph>
                    </Typography>
            })
        } else if (state === 0) {
            return Modal.warning({
                title: "已拒绝",
                content:
                    <Typography>
                        <Paragraph>
                            您的申请被拒绝，拒绝理由如下：
                        </Paragraph>
                        <Paragraph>
                            {comments}
                        </Paragraph>
                    </Typography>
            })
        } else if (state === 1) {
            let text;
            if (comments === null) {
                text = <Paragraph>您的申请已通过，恭喜🎉</Paragraph>
            } else {
                text = (
                    <>
                        <Paragraph>您的申请已通过，恭喜🎉， 审核意见：</Paragraph>
                        <Paragraph>{comments}</Paragraph>
                    </>
                )
            }

            return Modal.success({
                title: "已通过",
                content:
                    <Typography>
                        {text}
                    </Typography>
            })
        }
    }

    const handleDeleteApply = async (id: number) => {
        if (userStatue.isLogin) {
            const postValue = {
                'RAid': id,
            }
            let res = await handleWebsiteRequest(deleteRecommendApplyApi, postValue)

            await handleWebsiteResponse(res, (_) => {
                message.success("撤回成功")
                refreshApplyList()
            }, (_) => {
                message.warning("参数错误")
            }, (_) => {
                message.warning("服务器异常")
            })
        }
    }

    return (
        applyList.length ?
            <Table dataSource={applyList.slice(0, 10)} pagination={false} scroll={{x: true}}
                   columns={launchApplyColumn({
                       onShowApplyButton: (comments: string|null, state: number) => showAppliesInfo(comments, state),
                       onDeleteApply: (RAid: number) =>
                           handleDeleteApply(RAid),
                       showDetail: false,
                   })}
            />:
            <Empty
                description={
                    <span>
                        暂无申请记录
                    </span>
                }
                style={{margin: "24px"}}
            >
                <Button type={"primary"} onClick={props.onAdd}>
                    创建新申请
                </Button>
            </Empty>
    )
}