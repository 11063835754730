import React from "react";
import {Button, Checkbox, Form, Input, message} from "antd";
import {UserInfoManager} from "../../model/userInfo";
import {UserOutlined, LockOutlined} from "@ant-design/icons";
import {handleWebsiteRequest, handleWebsiteResponse} from "../../utils/tools";
import {loginApi} from "../../utils/api";

export const LoginForm: React.FC<{loginSuccess: () => void}> = (props) => {
    const [loading, setLoading] = React.useState(false)

    async function onFinish(values: { remember: boolean; Username: string; Password: string; }) {
        setLoading(true)
        if(values.remember) {
            UserInfoManager.statue.info.username = values.Username
            UserInfoManager.statue.info.password = values.Password
            UserInfoManager.save()
        }
        let res = await handleWebsiteRequest(loginApi, values, false)

        await handleWebsiteResponse(res, (data) => {
            message.success("登陆成功")
            UserInfoManager.statue.info.groupName = data.Data.Nickname
            UserInfoManager.statue.info.authority = data.Data.Authority
            UserInfoManager.statue.isLogin = true
            UserInfoManager.statue.save()
            props.loginSuccess()
        }, (_) => {
            message.warning("账号或密码错误")
        }, (_) => {
            message.warning("服务器异常")
        })
        setLoading(false)
    }

    return (
        <Form
            name="normal_login"
            className="login-form"
            initialValues={{
                username: UserInfoManager.statue.info.username,
                password: UserInfoManager.statue.info.password,
                remember: true,
            }}
            onFinish={onFinish}
        >
            <Form.Item
                name="Username"
                rules={[
                    {
                        required: true,
                        message: '请输入您的账号!',
                    },
                ]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="账号" />
            </Form.Item>
            <Form.Item
                name="Password"
                rules={[
                    {
                        required: true,
                        message: '请输入您的密码!',
                    },
                ]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="密码"
                />
            </Form.Item>
            <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>记住我</Checkbox>
                </Form.Item>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                    登陆
                </Button>
            </Form.Item>
        </Form>
    );
};