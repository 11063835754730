import {Button, Space, Tag} from "antd";
import {RecommendPositionToText} from "../../../model/config";
import {CheckCircleOutlined, ClockCircleOutlined} from "@ant-design/icons";
import React from "react";
import {ColumnsType} from "antd/es/table";
import {RecommendApplyListItem} from "../../../model/recommendApply";

interface LaunchApplyColumnProps {
    onShowApplyButton: (comments: string | null, state: number) => void,
    onDeleteApply: (RAid: number) => void,
    showDetail: boolean,
}

export const launchApplyColumn: (props: LaunchApplyColumnProps) => ColumnsType<RecommendApplyListItem>
    = (props) => {
    const column: ColumnsType<RecommendApplyListItem> = [
        {
            title: "申请id",
            dataIndex: "RAid",
            align: "center",
            width: "10%",
        },
        {
            title: "公告id",
            dataIndex: "Aid",
            align: "center",
            width: "10%",
        },
        {
            title: "公告标题",
            dataIndex: "Title",
            align: "center",
            width: "20%",
            ellipsis: true,
        },
        {
            title: "推荐位位置",
            dataIndex: "Position",
            align: "center",
            width: "15%",
            render: (position: number) => <Tag color={"blue"}>{RecommendPositionToText[position]}</Tag>
        },
        {
            title: "申请状态",
            dataIndex: "State",
            align: "center",
            width: "15%",
            render: (state: number) => {
                if (state === -1) {
                    return (
                        <Tag color={"default"} icon={<ClockCircleOutlined />}>待审核</Tag>
                    )
                } else if (state === 0) {
                    return (
                        <Tag color={"error"} icon={<ClockCircleOutlined />}>已拒绝</Tag>
                    )
                } else if (state === 1) {
                    return (
                        <Tag color={"success"} icon={<CheckCircleOutlined />}>已通过</Tag>
                    )
                }
            }
        },
        {
            title: "操作",
            key: "Action",
            align: "center",
            responsive: ['xl'],
            render: (_, record) => {
                return (
                    <Space>
                        <Button
                            type={"link"}
                            onClick={() => props.onShowApplyButton(record.Comments, record.State)}
                        >
                            查看审核详情
                        </Button>
                        <Button
                            onClick={() => props.onDeleteApply(record.RAid)}
                            type={"link"}
                        >
                            撤回申请
                        </Button>
                    </Space>
                )
            }
        },
    ]

    if (props.showDetail) {
        const temp: ColumnsType<any> = [
            {
                title: "推荐开始日期",
                dataIndex: "StartDate",
                align: "center",
                render: (startDate: string) => (
                    <>
                        {startDate === '0000-00-00' ? '无' : startDate}
                    </>
                )
            },
            {
                title: "推荐结束时间",
                dataIndex: "EndDate",
                align: "center",
                render: (endDate: string) => (
                    <>
                        {endDate === '0000-00-00' ? '无' : endDate}
                    </>)
            }
        ]

        // @ts-ignore
        column.splice.apply(column, [3, 0].concat(temp))
        column.splice(7, 0,
            {
                title: "申请时间",
                dataIndex: "UpdateTime",
                align: "center",
            }
        )
    }

    return column
}