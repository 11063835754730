import React, {useCallback, useEffect, useState} from "react";
import {Empty, message, Table} from "antd";
import {RecommendApplyListItem} from "../../../model/recommendApply";
import {launchExamineApplyColumn} from "./launchExamineApplyColumn";
import {useMyOutletContext} from "../../../pages/basePage";
import {handleWebsiteRequest, handleWebsiteResponse} from "../../../utils/tools";
import {getExamineApplyApi, LocalUrl} from "../../../utils/api";
import {getAuthority, UserInfoManager} from "../../../model/userInfo";
import {useNavigate} from "react-router";
import {ExamineApplyModal} from "../../modalComponents/examineApplyModal";

interface ExamineApplyTableProps {
    applyList: RecommendApplyListItem[]
    setApplyList: React.Dispatch<React.SetStateAction<RecommendApplyListItem[]>>
    setSelectDateRange: React.Dispatch<React.SetStateAction<[string, string] | null>>
}

export const ExamineApplyTable: React.FC<ExamineApplyTableProps> = ({applyList, setApplyList, setSelectDateRange}) => {
    const {userStatue: [userStatue, setUserStatue]} = useMyOutletContext()
    const navigate = useNavigate()

    const [showModal, setShowModal] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(false)
    const [selectedApply, setSelectedApply] = useState<RecommendApplyListItem | null>(null)

    const getExamineApplyList = useCallback(
        async () => {
            if (userStatue.info.authority === "super" || userStatue.info.authority === "auditor") {
                let res = await handleWebsiteRequest(getExamineApplyApi, {OnlyNeedReview: false})
                await handleWebsiteResponse(res, (data) => {
                    setApplyList(data.Applies)
                }, (_) => {
                    message.warning("参数错误")
                }, (_) => {
                    message.error("服务器错误")
                })
            } else {
                message.warning("无权访问此页面！")
                navigate(LocalUrl.homepage)
            }
        }, [userStatue.info]
    )

    useEffect(() => {
        (
            async () => {
                await getAuthority((_) => setUserStatue({...UserInfoManager.statue}))
                await getExamineApplyList()
            }
        )()
    }, [forceRefresh, userStatue])

    return (
        applyList.length ?
            <>
                <Table
                    dataSource={applyList}
                    pagination={false}
                    scroll={{x: true}}
                    columns={
                        launchExamineApplyColumn({
                            onClicked: (record) => {
                                setSelectedApply(record)
                                setShowModal(true)
                            }
                        })
                    }
                    onRow={(record) => {
                        return {
                            onMouseEnter: (_) => {setSelectDateRange([record.StartDate, record.EndDate])},
                            onMouseLeave: (_) => {setSelectDateRange(null)}
                        }
                    }}
                />
                <ExamineApplyModal open={showModal} setOpen={setShowModal} applyInfo={selectedApply}
                                   refreshApply={() => setForceRefresh(!forceRefresh)}/>
            </>
            :
            <Empty
                description={
                    <span>
                        暂无新申请
                    </span>
                }
                style={{margin: "24px"}}
            />

    )
}