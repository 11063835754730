import React, {useEffect, useState} from "react";
import {useMyOutletContext} from "./basePage";
import {MarkdownPreview} from "../components/markdownComponents/markdownPreview";
import {Button, Col, message, Row, Space, Tooltip, Typography} from "antd";
import {DateRangePickerPopover} from "../components/markdownComponents/dateRangePickerPopover";
import {getAnnouncement, SendAnnouncementParams} from "../model/announcement";
import {addAnnouncementApi, Api, getAnnouncementApi, LocalUrl, updateAnnouncementApi} from "../utils/api";
import {handleWebsiteRequest, handleWebsiteResponse} from "../utils/tools";
import {defaultCos} from "../utils/cos";
import {UploadAnnouncementCover} from "../components/markdownComponents/uploadAnnouncementCover";

const {Title} = Typography

export const MarkdownPage: React.FC = (props) => {
    const {recentAnnouncement: [announcementData,]} = useMyOutletContext()
    const {userStatue: [{isLogin},]} = useMyOutletContext()

    const [isSending, setIsSending] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [announcementInfo, setAnnouncementInfo] = useState({...announcementData})
    const [inputText, setInputText] = useState("")

    const sendMarkdown = async () => {
        setIsSending(true)
        let url: Api;
        let value: SendAnnouncementParams = {
            Title: announcementInfo.title!,
            Markdown: inputText,
            StartDate: announcementInfo.startDate ? announcementInfo.startDate : "null",
            EndDate: announcementInfo.endDate ? announcementInfo.endDate : "null",
            CoverUrl: announcementInfo.coverUrl ? announcementInfo.coverUrl : "null",
        }
        if (announcementInfo.type === 'add') {
            url = addAnnouncementApi
        } else if (announcementInfo.type === 'update') {
            url = updateAnnouncementApi
            value.Id = announcementInfo.aid!
            value.IsDelete = false
        }

        let res = await handleWebsiteRequest(url!, value)

        await handleWebsiteResponse(res, (_) => {
            message.success("发送成功")
            setTimeout(() => window.location.href = LocalUrl.homepage, 1000)
        }, (_) => {
            message.warning("参数错误")
        }, (_) => {
            message.warning("服务器异常")
        })
        setIsSending(false)
    }

    useEffect(() => {
        (
            async () => {
                if (announcementInfo.type === "update") {
                    setIsLoading(true)
                    await getAnnouncement(announcementInfo.aid!, (markdown) => setInputText(markdown))
                    setIsLoading(false)
                }
            }
        )()
    }, [])

    let dataWidget;

    if (announcementInfo === undefined ||
        announcementInfo.startDate === undefined || announcementInfo.endDate === undefined ||
        announcementInfo.startDate === null || announcementInfo.endDate === null ||
        announcementInfo.startDate === '0000-00-00' || announcementInfo.endDate === '0000-00-00') {
        dataWidget = <Title level={5}>无举办日期</Title>
    } else {
        dataWidget = <Title level={5}>{announcementInfo.startDate + ' 至 ' + announcementInfo.endDate}</Title>
    }

    let sendButton = (
        <Button type={"primary"} shape={"round"}
                disabled={!isLogin}
                onClick={sendMarkdown}
                loading={isSending}
        >
            保存
        </Button>
    )

    let uploadButton = (
        <UploadAnnouncementCover
            cos={defaultCos}
            afterUpload={(url: string) => {
             setAnnouncementInfo({...announcementInfo, coverUrl: url})
            }}
            defaultFiles={
            announcementInfo.coverUrl ?
                [{uid: '1', name: announcementInfo.coverUrl.split('/')[2], status: "done", url: announcementInfo.coverUrl}]
                :
                []
            }
            handelRemove={(_) => {
                announcementInfo.coverUrl = null
            }}
        />
    )

    if (!isLogin){
        sendButton = (
            <Tooltip title={'请登陆后再发送'}>
                {sendButton}
            </Tooltip>
        )

        uploadButton = (
            <Tooltip title={'请登陆后再发送'}>
                {uploadButton}
            </Tooltip>
        )
    }

    return (
        <Space direction={"vertical"} style={{ display: 'flex', padding: '0 50px'}}>
            <Row justify={"space-between"} align={"bottom"}>
                <Col>
                    <Title level={2}
                           editable={{
                               onChange: (str: string) => {
                                   if (announcementInfo.title!.length > 0) {
                                       setAnnouncementInfo({...announcementInfo, title: str})
                                   } else {
                                       message.warning('标题不能为空')
                                   }

                               },
                               maxLength: 24,
                           }}
                    >{announcementInfo.title!}</Title>
                </Col>
                <Col>
                    <Space>
                        {dataWidget}
                        <DateRangePickerPopover onOk={(startDate, endDate) => {
                            setAnnouncementInfo({...announcementInfo, startDate: startDate, endDate: endDate})
                        }} />
                    </Space>
                </Col>
            </Row>
            <Row align={"bottom"} justify={"space-between"}>
                <Col span={10}>
                    {uploadButton}
                </Col>
                <Col span={1}>
                    <Space>
                        {sendButton}
                    </Space>
                </Col>
            </Row>
            <MarkdownPreview inputText={inputText} isLoading={isLoading} onInputTextChange={setInputText}/>
        </Space>
    )
}