import {Button, Upload, UploadFile, UploadProps} from "antd";
import React from "react";
import ImgCrop from "antd-img-crop";
import {cquBucket, cquRegion} from "../../model/config";
import {UserInfoManager} from "../../model/userInfo";
import {useMyOutletContext} from "../../pages/basePage";
import COS from "cos-js-sdk-v5";

interface UploadAnnouncementCoverProps {
    cos: COS,
    afterUpload: (path: string) => void
    defaultFiles?: UploadFile<any>[]
    handelRemove: (fileName: string) => void
}

export const UploadAnnouncementCover: React.FC<UploadAnnouncementCoverProps> = ({cos, afterUpload, defaultFiles, handelRemove}) => {
    const {userStatue: [{isLogin},]} = useMyOutletContext()

    const uploadProps: UploadProps = {
        accept: 'image/*',
        maxCount: 1,
        defaultFileList: defaultFiles,
        customRequest: (params: any) => {
            const key = 'announcements/' +
                (UserInfoManager.statue.info.username ? UserInfoManager.statue.info.username : "unknow") + '/'
                + params.file.name
            cos.putObject({
                Bucket: cquBucket,
                Region: cquRegion,
                Key: key,
                Body: params.file,
                onProgress: (processData: any) => {
                    params.onProgress({percent: processData.percent}, params.file.name)
                },
            }, (err: any, data: any) => {
                if (err) {
                    console.log(err.message)
                    params.onError(err.message)
                } else {
                    params.onSuccess('success', params.file.name)
                    afterUpload(key)
                }
            })
        },
        onRemove: (file) => {
            handelRemove(file.name)
            return true
        }
    }

    return (
        <ImgCrop aspect={15/8}>
            <Upload {...uploadProps}>
                <Button type={"primary"} shape={"round"} disabled={!isLogin}>
                    上传公告封面
                </Button>
            </Upload>
        </ImgCrop>
    )
}