import {Col, DatePicker, Input, message, Modal, Row, Space} from "antd";
import React, {useCallback, useEffect} from "react";
import {AnnouncementUrl, LocalUrl} from "../../utils/api";
import {useMyOutletContext} from "../../pages/basePage";
import {useNavigate} from "react-router";
import {AnnouncementData} from "../../model/announcement";

const {RangePicker} = DatePicker

interface AddNewAnnouncementModalProps {
    visible: boolean,
    setVisible: (value: boolean) => void
}

export const AddNewAnnouncementModal: React.FC<AddNewAnnouncementModalProps> = (props) => {
    const {recentAnnouncement: [announcementData, setAnnouncementData]} = useMyOutletContext()
    const navigate = useNavigate()

    const handleDate = (date: any, dateString: [string, string]) => {
        if (date !== null) {
            setAnnouncementData({...announcementData, startDate: dateString[0], endDate: dateString[1]})
        }
    }

    useEffect(() => {
        let temp: AnnouncementData = {
            title: null,
            type: "add",
            startDate: null,
            endDate: null
        }

        setAnnouncementData(temp)
    }, [])

    const handleOk = useCallback(() => {
        if (announcementData.title === null) {
            message.warning('标题为必填项')
            return
        }
        navigate(LocalUrl.announcement + AnnouncementUrl.add)
    }, [announcementData])


    return (
        <Modal title={"新建活动公告"}
               open={props.visible}
               onCancel={() => props.setVisible(false)}
               onOk={handleOk}
        >
            <Space direction={"vertical"}>
                <Row justify={"space-around"} align={"middle"}>
                    <Col span={8}  style={{textAlign: 'center'}}>
                        <div>公告标题</div>
                    </Col>
                    <Col span={15}>
                        <Input showCount maxLength={24}
                               onChange={(event) => setAnnouncementData({...announcementData, title: event.target.value})}
                               allowClear />
                    </Col>
                </Row>
                <Row justify={"space-around"} align={"middle"}>
                    <Col span={8}  style={{textAlign: 'center'}}>
                        <div>活动开始、结束时间（可选）</div>
                    </Col>
                    <Col span={15}>
                        <RangePicker
                            style={{width: '100%'}}
                            // disabledDate={(current) => current && current < moment().endOf('day')}
                            onChange={(date, dateString) => handleDate(date, dateString)} />
                    </Col>
                </Row>
            </Space>
        </Modal>
    )
}